import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f71ec53628e1291fc218114e84dd9073@o4507827807780864.ingest.de.sentry.io/4507827847757904",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/slonopotam\.net\/v2/, /^https:\/\/dybr\.space\/v2/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { unregister } from './registerServiceWorker';
import 'index.css';
import 'components/Froala/plugins/tribute.css';
import 'components/Froala/plugins/hide_license_warning.css';

import 'froala-editor/js/froala_editor.pkgd.min';
import 'configs/translations/froala/ru.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'font-awesome/css/font-awesome.css';
import $ from 'jquery';

import axios from 'axios';

import theme from 'styles/DybrTheme';
import { Provider } from 'unstated';
import { Provider as ReduxProvider } from 'react-redux';
import { store as reduxStore } from 'store';

import ErrorBoundary from 'components/Helpers/ErrorBoundary';

import { API_URL } from 'configs/dybr';

import moment from 'moment';
import 'moment/locale/ru';
import { AppContainer } from 'App';
import { StateMonitorContainer } from 'StateMonitor';
import PrivacyListProvider from 'scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';

moment.locale('ru');

unregister(); // disable service workers

window.$ = $;

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/vnd.api+json';
axios.defaults.headers.patch['Content-Type'] = 'application/vnd.api+json';
axios.defaults.timeout = 1000 * 60 * 5;

render(
  <ReduxProvider store={reduxStore}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Provider>
          <StateMonitorContainer>
            <PrivacyListProvider>
              <AppContainer />
            </PrivacyListProvider>
          </StateMonitorContainer>
          <div id="modal-root" />
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </ReduxProvider>,
  document.querySelector('#root')
);
